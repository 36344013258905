/**
 * @file It contains scripts for message view
 */
import { mapGetters, mapActions } from "vuex"
import ThemisFileComponent from "@/components/shared/file"
import ThemisPopOver from "@/components/shared/pop-over"
import ThemisDecision from "@/components/shared/decision"
import { UNSUPPORTED_MIME_TYPE } from "@/constants"

export default {
  name      : "Message",
  components: {
    ThemisFileComponent,
    ThemisPopOver,
    ThemisDecision
  },
  data() {
    return {
      files                             : new Array,
      isAttachmentDialogVisible         : false,
      textMessage                       : undefined,
      isSendMessageDecisionDialogVisible: false,
      isAttachmentsIssuesDialogVisible  : false,
      notExistingFiles                  : new Array
    }
  },
  methods: {
    ...mapActions({
      uploadAttachments: "messageItems/uploadAttachments",
      removeAttachment : "messageItems/removeAttachment",
      sendReport       : "reports/sendReport",
      notify           : "shared/notify"
    }),
    async handleSaveAttachments() {
      const filesToUpload = []
      if (!this.isFileUploadLimitReached) {
        for (const file of this.files) {
          if (file.size > this.$CONSTANTS.FILE_SIZE_LIMIT) {
            this.notify({
              type: "error",
              text: this.$t("122", {
                name: file.name
              }),
              icon: "mdi-alert-circle"
            })
          } else {
            filesToUpload.push(file)
          }
        }
        this.files = filesToUpload
        this.uploadAttachments({
          id   : +this.$route.params.messageId,
          files: this.files
        })
      } else {
        this.files = new Array
      }
    },
    handleDeleteFile(removedFile) {
      this.files = this.files.filter(file => file.name !== removedFile.name
        && file.type !== removedFile.type)
      this.removeAttachment(removedFile.id)
    },
    handleSendButtonClick() {
      for(const messageItem of this.messageItems) {
        if(!messageItem.fileExists) {
          this.notExistingFiles.push(messageItem)
        }
      }
      if(this.notExistingFiles.length) {
        this.isAttachmentsIssuesDialogVisible = true
      } else {
        this.isSendMessageDecisionDialogVisible = true
      }
    },
    sendMessage() {
      this.sendReport({
        messageId : +this.$route.params.messageId,
        value     : this.textMessage,
        languageId: this.reporterLanguage.id,
        type      : this.$CONSTANTS.MESSAGE_ITEM_TYPE.TEXT })
    },
    sendAnyway() {
      for (const messageItem of this.notExistingFiles) {
        this.removeAttachment(messageItem.id)
        this.sendReport({
          messageId : +this.$route.params.messageId,
          value     : this.textMessage,
          languageId: this.reporterLanguage.id,
          type      : this.$CONSTANTS.MESSAGE_ITEM_TYPE.TEXT
        })
      }
    },
    goBack() {
      for (const messageItem of this.notExistingFiles) {
        this.removeAttachment(messageItem.id)
      }
      this.isAttachmentsIssuesDialogVisible = false
      this.notExistingFiles                 = new Array
    }
  },
  computed: {
    ...mapGetters({
      reporterLanguage      : "auth/reporterLanguage",
      messageItems          : "messageItems/messageItems",
      messages              : "messages/messages",
      isUploadingAttachments: "messageItems/isUploadingAttachments",
      channels              : "channels/channels",
      isSendingReport       : "reports/isSendingReport",
      isReportSent          : "reports/isReportSent"
    }),
    isMobileView() {
      return this.$vuetify.breakpoint.smAndDown
    },
    savedFiles() {
      return this.messageItems.filter(messageItem => messageItem.messageId === +this.$route.params.messageId &&
        messageItem.type !== this.$CONSTANTS.MESSAGE_ITEM_TYPE.TEXT)
    },
    allowableFileFormats() {
      const allowableFileFormats = []
      for(const messageItemType of Object.values(this.$CONSTANTS.MESSAGE_ITEM_TYPE)) {
        if (messageItemType !== this.$CONSTANTS.MESSAGE_ITEM_TYPE.TEXT) {
          allowableFileFormats.push(messageItemType)
        }
      }
      return allowableFileFormats
    },
    savedAndUnsavedFiles() {
      const result = this.savedFiles?.map(savedFile => ({
        id  : savedFile.id,
        name: savedFile.value.name,
        type: savedFile.type,
        size: savedFile.value.size
      }))
      if (this.files?.length > 0 && result) {
        for (const file of this.files) {
          if (!result.find(savedFile => {
            return savedFile.name === file.name &&
            (savedFile.type === UNSUPPORTED_MIME_TYPE.MSG ||  savedFile.type === file.type)
            && savedFile.size === file.size
          })) {
            result.push({
              name: file.name,
              type: file.type,
              size: file.size
            })
          }
        }
      }
      return result
    },
    isFileUploadLimitReached() {
      if (this.savedAndUnsavedFiles.length > this.$CONSTANTS.ALLOWABLE_FILE_LIMIT) {
        return true
      }
    },
    channel() {
      return this.channels.find(channel => channel.name === this.$route.params.channel)
    }
  },
  watch: {
    textMessage: {
      handler: function(newValue) {
        if (newValue && (newValue.length < 50000 && newValue.length > 48000)) {
          this.notify({
            type: "warning",
            text: "52",
            icon: "mdi-alert-circle"
          })
        }
      }
    },
    isSendingReport: {
      handler: function(newValue) {
        this.$DECISIONS.SEND_MESSAGE.pActions[1].buttonProps.loading       = newValue
        this.$DECISIONS.FILE_NOT_AVAILABLE.pActions[1].buttonProps.loading = newValue
      }
    },
    isReportSent: {
      handler: function(newValue) {
        if (newValue) {
          this.isSendMessageDecisionDialogVisible = false
          this.isAttachmentsIssuesDialogVisible   = false
          this.notify({
            type: "success",
            text: "55"
          })
          this.$router.push({
            name  : "acknowledgement",
            params: {
              locale : this.$route.params.locale,
              channel: this.$route.params.channel
            }
          })
        }
      }
    }
  }
}
